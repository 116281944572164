import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import { CorePage } from '../types/wordpress'
import WhiteBox from '../components/WhiteBox'
import Header from '../components/Header'
import Footer from '../components/Footer/Footer'
import { Heading } from '../components/typography'

type IndexPageProps = {
    pageContext: {
        allPages: CorePage[]
        numberOfPages: number
    }
}

const ContentContainer = styled.div`
    margin: 70px auto;
    text-align: center;
`

const HeadingContainer = styled.div`
    margin-bottom: 70px;
`
// http://localhost:8000/ this is what you see
const IndexPage = ({ pageContext: { allPages, numberOfPages } }: IndexPageProps) => (
    <WhiteBox noPadding>
        <Helmet title="Home | InterNations" />
        <Header />
        <ContentContainer>
            <HeadingContainer>
                <Heading>Hi people</Heading>
                <p>Welcome to your new Gatsby site.</p>
                <p>Now go build something great.</p>
            </HeadingContainer>

            <Heading>All pages from Wordpress</Heading>
            <p>{numberOfPages} pages were built.</p>
            {allPages.map(page => (
                <Link key={page.meta.path} to={page.meta.path}>
                    <p>{page.meta.title}</p>
                </Link>
            ))}
        </ContentContainer>
        <Footer />
    </WhiteBox>
)

export default IndexPage
